import React, { useState } from "react";

const WhatWeDo = () => {
  const [activeTab, setActiveTab] = useState(0);

  const whatDo = [
    {
      title: "Workforce Planning",
      desc: "Turnover Analysis. Understanding Talent Supply. Succession Planning.",
    },
    {
      title: "Data Strategy",
      desc: "Lead your HR function with Data-Strategy for: HR Analytics Maturity Assessment.",
    },
    {
      title: "Talent Management",
      desc: "Employee Headcount/Demographics. Reduce recruitment costs. Improve retention.",
    },
    {
      title: "Employee Engagement",
      desc: "Engagement surveys. Enhance experience. Boost productivity.",
    },
  ];

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="py-28">
      <div className="container mx-auto px-4">
        {/* Section Heading */}
        <div className="text-center mb-12">
          <h2 className="text-[#444444] text-4xl font-bold py-6">
            Our Services
          </h2>
          <p className="text-[#777777] diffFont text-lg sm:text-md">
            Leading consultants in innovation and sustainability, enhancing
            business outcomes through data-driven insights on people.
          </p>
        </div>

        {/* Grid Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8   shadow-md p-6 rounded-lg">
          {whatDo.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center p-5 md:p-8 rounded-lg cursor-pointer transition duration-300 ${
                activeTab === index
                  ? "bg-gray-200 border-t-4 border-[#EE3364]"
                  : "border-t-2 border-transparent"
              }`}
              onClick={() => toggleTab(index)}
            >
              <h2 className="text-lg font-semibold text-[#EE3364] mb-2">
                {item.title}
              </h2>
              <p className="text-gray-600">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
