import React, { useState, useEffect, useRef } from "react";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { FaPaperclip, FaTimes } from "react-icons/fa";

import { getUsernameInitials } from "../utils/utils";
import { RiSendPlaneFill } from "react-icons/ri";
import ScaleLoader from "react-spinners/ClipLoader";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();
mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";
const robotImage = process.env.PUBLIC_URL + "/Image.jpeg";
const MessageInput = () => {
  const [reload, setReload] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState("");
  const { accounts } = useMsal();
  const fileInputRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [initials, setInitials] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const chatContainerRef = useRef(null);
  const [file, setFile] = useState(null);
  const [tempChat, setTempChat] = useState("");
  const textareaRef = useRef(null);
  const [prevHeadingId, setPrevHeadingId] = useState(
    sessionStorage.getItem("headingId") || ""
  );

  const [graphPaths, setGraphPaths] = useState({});

  useEffect(() => {
    setReload(true);
    console.log("Component reloaded");

    if (accounts.length > 0) {
      const account = accounts[0];
      setName(account.name);
      setEmail(account.username);
      sessionStorage.setItem("userEmail", account.username);
      setInitials(getUsernameInitials(account.name));
    }
  }, [accounts]);

  useEffect(() => {
    if (name) {
      const welcomeMessage = {
        message: `Welcome ${name}, How can I help?`,
        isUser: false,
      };
      setChatHistory([welcomeMessage]);
    }
  }, [name]);

  useEffect(() => {
    const fetchHistory = async (headingId) => {
      try {
        const response = await axios.post(
          "https://8v7oil0d5b.execute-api.us-east-1.amazonaws.com/satori-dev/satori-history",
          {
            email,
            headingId,
          }
        );

        const { status, history } = response.data;

        if (status === "success") {
          const { messages } = history;

          // Prepare the formatted chat history
          const formattedMessages = [
            { message: `Welcome ${name}, How can I help?`, isUser: false },
            ...messages.flatMap((message) => [
              { message: message.user_question, isUser: true },
              { message: message.model_response, isUser: false },
            ]),
          ];

          // Check for a graph path in each message
          messages.forEach((message) => {
            if (message.model_response.includes(".png")) {
              const updatedGraphPath = message.model_response.replace(
                "exports/",
                ""
              );
              setGraphPaths((prevPaths) => [...updatedGraphPath]);
            }
          });

          // Update chat history in state and local storage
          setChatHistory(formattedMessages);
          localStorage.setItem(
            "chatHistory",
            JSON.stringify(formattedMessages)
          );
        } else {
          console.error("Error fetching history:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
      }
    };

    const intervalId = setInterval(() => {
      const storedHeadingId = sessionStorage.getItem("headingId");
      if (storedHeadingId) {
        if (storedHeadingId !== prevHeadingId || reload) {
          setPrevHeadingId(storedHeadingId);
          localStorage.setItem("prevHeadingId", storedHeadingId);
          fetchHistory(storedHeadingId);
          setReload(false);
          localStorage.setItem("reload", "false");
        }
      }

      const storedNewChat = sessionStorage.getItem("newChatClicked");
      if (storedNewChat === "1") {
        setTempChat(storedNewChat);

        const welcomeMessage = {
          message: `Hi ${name} ! It's great  to have you back. How can I assist you ?`,
          isUser: false,
        };
        setChatHistory([welcomeMessage]);
        localStorage.setItem("chatHistory", JSON.stringify([welcomeMessage]));
        sessionStorage.setItem("newChatClicked", "");
        sessionStorage.setItem("headingId", "");
        setPrevHeadingId("");
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [email, prevHeadingId, reload, name]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const [userTyping, setUserTyping] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState("35px");

  const handleTextareaResize = (event) => {
    const { scrollHeight } = event.target;
    const maxHeight = 340;

    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = textareaHeight;
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const handleListen = () => {
      if (isListening) {
        mic.start();
        mic.onend = () => {
          mic.start();
        };
      } else {
        mic.stop();
        mic.onend = () => {};
      }
      mic.onstart = () => {};

      mic.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        setNote(transcript);

        mic.onerror = (event) => {
          console.log(event.error);
        };
      };
    };

    handleListen();
  }, [isListening]);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUserInput = (event) => {
    const { value } = event.target;
    setUserTyping(true);
    setNote(value);
    if (value) {
      handleTextareaResize(event);
    }
  };

  // Handle User Submission
  const handleUserSubmit = async () => {
    if (!note.trim() && !file) return;

    // Add user's message to chat history
    const newMessage = { message: note, isUser: true };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);

    const user_input = note;
    const storedHeadingId = sessionStorage.getItem("headingId") || "";
    const storedNewChat = sessionStorage.getItem("newChatClicked") || "false";

    setIsListening(false);
    setUserTyping(false);
    setTextareaHeight("35px");
    setNote("");
    setLoading(true);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("user_input", user_input);
    formData.append("newChat", storedNewChat);
    formData.append("headingId", storedHeadingId);
    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await axios.post(
        "https://8v7oil0d5b.execute-api.us-east-1.amazonaws.com/satori-dev/Satori-response",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedGraphPath = response.data.response.includes(".png")
        ? response.data.response.replace("exports/charts/", "")
        : null;
      if (updatedGraphPath) {
        fetchChart(updatedGraphPath);
      }
      // setGraphPath(updatedGraphPath);

      if (response.data.last_heading_id) {
        sessionStorage.setItem("headingId", response.data.last_heading_id);
      }

      // Process response text and add to chat history
      let assistantMessageText = response.data.response;

      if (typeof assistantMessageText === "object") {
        assistantMessageText = JSON.stringify(assistantMessageText, null, 2);
      }

      const assistantMessage = {
        message: assistantMessageText,
        isUser: false,
        graphPath: updatedGraphPath,
      };

      setChatHistory((prevHistory) => [...prevHistory, assistantMessage]);
    } catch (error) {
      console.error("Error generating response:", error);
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { message: "Error generating response.", isUser: false },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const fetchChart = async (filename, assistantMessageText) => {
    try {
      const response = await axios.post(
        "https://8v7oil0d5b.execute-api.us-east-1.amazonaws.com/satori-dev/Chats",
        { filename },
        {
          responseType: "blob",
        }
      );

      const url = URL.createObjectURL(response.data);
      // Assuming headingId or message index is used as key for the graph
      setGraphPaths((prevPaths) => ({
        ...prevPaths,
        [filename]: url,
      }));
    } catch (error) {
      console.error("Error fetching chart:", error);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center h-screen mt-4 bg-gray-20">
        <div
          ref={chatContainerRef}
          className="w-full md:w-3/4 flex flex-col items-center overflow-y-auto pb-8 mt-24 scrollbar-hide"
          style={{ maxHeight: "100%" }}
        >
          {chatHistory.map((item, i) => (
            <div
              key={i}
              className={`mb-1 ${
                i === chatHistory.length - 1 ? "mb-4" : ""
              } rounded-lg p-4 w-full md:w-[75%] ${
                item.isUser
                  ? "text-white flex xl:justify-end"
                  : "text-white flex xl:justify-start"
              }`}
            >
              <div className="flex items-start">
                {item.isUser ? (
                  <div className="rounded-full w-14 h-12 text-[#EE3364] bg-gray-100 flex items-center justify-center">
                    {initials}
                  </div>
                ) : (
                  <div className="rounded-full w-14 h-12 flex items-center justify-center">
                    <img src={robotImage} alt="Robot" className="w-10 h-10" />
                  </div>
                )}
                <div className="flex flex-col w-full xl:max-w-[480px] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                  <p className="text-sm font-normal py-1.5 text-gray-900 dark:text-white w-full">
                    {item.message}
                  </p>

                  {!item.isUser &&
                    item.graphPath &&
                    graphPaths[item.graphPath] && (
                      <a
                        href={graphPaths[item.graphPath]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={graphPaths[item.graphPath]}
                          alt={`Generated Graph`}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            border: "2px solid #ccc",
                            borderRadius: "8px",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    )}
                </div>
              </div>
            </div>
          ))}

          {loading && (
            <div className="mb-1 rounded-lg p-4 w-[80%] md:w-[60%] text-white flex justify-start">
              <div className="flex items-start gap-1.5">
                <div className="rounded-full w-14 h-12 flex items-center justify-center">
                  <img src={robotImage} alt="Robot" className="w-10 h-10" />
                  <div className="flex items-center justify-center">
                    <ScaleLoader
                      color={"#EE3364"}
                      loading={loading}
                      size={32}
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {file && (
          <div className="fixed bottom-20 inline-flex items-center left-43 bg-white border-2 rounded-lg p-2 mb-2">
            <div className="flex items-center flex-1">
              <p className="text-sm text-gray-800 truncate">{file.name}</p>
            </div>
            <button onClick={handleRemoveFile} className="text-red-500 ml-2">
              <FaTimes size={20} />
            </button>
          </div>
        )}
      </div>
      <div
        className={`fixed bottom-0 left-1/2 transform -translate-x-1/2 flex items-center bg-white border-2 rounded-lg w-full md:w-1/2 p-2 mb-4`}
      >
        <label
          htmlFor="file-upload"
          className="cursor-pointer hover:text-gray-500 text-gray-600 py-1 px-2 mr-2"
        >
          <FaPaperclip size={20} />
        </label>

        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          ref={fileInputRef}
          className="hidden"
        />

        <textarea
          ref={textareaRef}
          value={note}
          onChange={handleUserInput}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleUserSubmit();
            }
          }}
          style={{ height: textareaHeight, maxHeight: "340px" }}
          placeholder="Ask Satori"
          className="flex-1 bg-white text-black py-2 px-4 focus:outline-none focus:ring-0 focus:border-none border-none resize-none overflow-hidden"
        ></textarea>

        <div className="flex items-center">
          <button
            onClick={handleUserSubmit}
            className="hover:text-red text-white font-semibold py-2 px-2 ml-2"
          >
            <RiSendPlaneFill size={24} color="#183137" />
          </button>

          <button
            onClick={() => setIsListening((prevState) => !prevState)}
            className="hover:text-yellow text-white font-semibold py-2 px-2 ml-1"
          >
            {isListening ? (
              <FaMicrophone
                size={24}
                color="green"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setNote("");
                    handleUserSubmit();
                  }
                }}
              />
            ) : (
              <FaMicrophoneSlash size={24} color="red" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default MessageInput;

const css = `
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
 
        .scrollbar-hide {
          -ms-overflow-style: none;  
          scrollbar-width: none;
        }
        `;

const style = document.createElement("style");
style.type = "text/css";
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
