import React from "react";

const Logo = () => {
  return (
    <img
      src="/images/logohr.png"
      className="w-[246px] h-[65px]  ml-[-40px]"
      alt=""
    />
  );
};

export default Logo;
