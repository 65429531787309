export const msalConfig = {
  auth: {
    clientId: "512d9306-c318-40f2-b773-f1f1c91ff1c0",
    authority:
      "https://login.microsoftonline.com/2749b2cd-bfe7-408a-aece-105d5d0f241a",
    redirectUri: "https://dev.satori-web.com",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
