import React, { useState } from "react";
import Profile from "../../profile/Profile";
import Sidebar from "./Sidebar";

const InsideBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className=" fixed w-full z-20 top-0 start-0 xs:bg-white sm:bg-white sml:bg-white">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto xs:p-2 p-2 sm:p-2 sml:p-2">
        <div>
          <Sidebar />
        </div>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            onClick={handleToggle}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded={menuOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            {menuOpen ? <Profile /> : <Profile />}
          </button>
        </div>
        <div
          className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
            menuOpen ? "block" : "hidden"
          }`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-2 md:p-0 mt-0 font-medium md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-red dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <li>
              <Profile />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default InsideBar;
