import React, { useRef, useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { BiHistory, BiPlus } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import { Divider } from "@mui/material";
import axios from "axios";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [histories, setHistories] = useState([]);
  const [showFirstQuestion, setShowFirstQuestion] = useState(false);
  const [selectedHeadingId, setSelectedHeadingId] = useState("");
  const ref = useRef(null);
  const [email, setEmail] = useState("");
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [newChatClicked, setNewChatClicked] = useState(false);

  useClickAway(ref, () => setOpen(false));

  const toggleSidebar = () => setOpen((prev) => !prev);

  const fetchHistory = async () => {
    try {
      setIsLoadingHistory(true);
      const email = sessionStorage.getItem("userEmail");
      setEmail(email);
      const response = await axios.post(
        "https://04j2nydt7k.execute-api.us-east-1.amazonaws.com/satori-prod/histories",
        {
          email,
        }
      );

      if (
        response.data.status === "success" &&
        response.data.histories.length > 0
      ) {
        const categorizedHistories = categorizeHistories(
          response.data.histories
        );
        setHistories(categorizedHistories);
        setShowFirstQuestion(true);
      } else {
        setHistories([]);
        setShowFirstQuestion(false);
      }
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const categorizeHistories = (histories) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);
    const last30Days = new Date(today);
    last30Days.setDate(last30Days.getDate() - 30);

    const categorized = {
      today: [],
      yesterday: [],
      last7days: [],
      last30days: [],
    };

    histories.forEach((history) => {
      const historyDate = new Date(history.created_at);

      if (isSameDate(historyDate, today)) {
        categorized.today.push(history);
      } else if (isSameDate(historyDate, yesterday)) {
        categorized.yesterday.push(history);
      } else if (historyDate > lastWeek) {
        categorized.last7days.push(history);
      } else if (historyDate > last30Days) {
        categorized.last30days.push(history);
      }
    });

    return categorized;
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleHistoryClick = (headingId) => {
    setSelectedHeadingId(headingId);
    setShowFirstQuestion(false);
    sessionStorage.setItem("headingId", headingId);
  };

  useEffect(() => {
    if (selectedHeadingId) {
      sessionStorage.setItem("headingId", selectedHeadingId);
    }
  }, [selectedHeadingId]);

  useEffect(() => {
    if (open) {
      setHistories([]);
      setShowFirstQuestion(false);
    }
  }, [open]);

  const handleNewChatClick = () => {
    setOpen(false);
    setSelectedHeadingId(null);
    setShowFirstQuestion(false);
    setNewChatClicked(true);
    sessionStorage.setItem("newChatClicked", "1");
  };

  return (
    <>
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-50 p-2 bg-white rounded-xl shadow-md"
        aria-label="toggle sidebar"
      >
        <GiHamburgerMenu size={20} />
      </button>
      <AnimatePresence initial={false}>
        {open && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.2 } }}
              transition={{ duration: 0.3 }}
              aria-hidden="true"
              className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-black bg-opacity-50"
            ></motion.div>
            <motion.div
              initial={{ x: "-100%" }}
              animate={{ x: 0 }}
              exit={{ x: "-100%" }}
              transition={{ duration: 0.3 }}
              className="fixed top-0 bottom-0 left-0 z-50 w-full h-screen max-w-xs border-r-2 border-zinc-800 bg-black flex flex-col justify-between"
              ref={ref}
              aria-label="Sidebar"
            >
              <div>
                <div className="flex items-center justify-between p-2 text-white ml-4">
                  <span className="text-xl font-bold">HR3SIXTY</span>
                  <button
                    onClick={toggleSidebar}
                    className="p-3 rounded-xl"
                    aria-label="close sidebar"
                  >
                    <IoCloseOutline
                      color="white"
                      size={24}
                      className="text-bold"
                    />
                  </button>
                </div>
                <Divider className="border-2" />
                <ul>
                  <li>
                    <div
                      onClick={handleNewChatClick}
                      className="flex items-center justify-between gap-2 m-4 transition-all hover:text-[#EE3364] border-zinc-800 text-white"
                    >
                      <motion.span {...framerText(0)}>New Chat</motion.span>
                      <motion.div {...framerIcon}>
                        <BiPlus className="text-2xl" />
                      </motion.div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={
                        showFirstQuestion
                          ? () => setShowFirstQuestion(false)
                          : fetchHistory
                      }
                      className="flex items-center justify-between gap-2 m-4 transition-all hover:text-[#EE3364] border-zinc-800 text-white"
                    >
                      <motion.span {...framerText(1)}>History</motion.span>
                      <motion.div {...framerIcon}>
                        <BiHistory className="text-2xl" />
                      </motion.div>
                    </div>
                    <AnimatePresence>
                      {isLoadingHistory && (
                        <motion.div
                          key="loader"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="flex justify-center items-center mt-4"
                        >
                          <div className="loader text-[#EE3364]">
                            Loading...
                          </div>
                        </motion.div>
                      )}
                      {showFirstQuestion && (
                        <motion.div
                          key="firstQuestion"
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          className="overflow-hidden flex flex-col gap-2 m-4 text-white"
                        >
                          {histories.today.length > 0 && (
                            <>
                              <div className="text-gray-300 text-md ml-2">
                                Today
                              </div>
                              {histories.today.map((history, index) => (
                                <motion.span
                                  key={index}
                                  {...framerText(2)}
                                  className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[80%] ml-6 rounded-lg items-center justify-start hover:text-[#EE3364]"
                                  onClick={() => {
                                    handleHistoryClick(history.headingId);
                                    toggleSidebar();
                                  }}
                                >
                                  {history.heading}
                                </motion.span>
                              ))}
                            </>
                          )}
                          {histories.yesterday.length > 0 && (
                            <>
                              <div className="text-gray-300 text-md ml-2">
                                Yesterday
                              </div>
                              {histories.yesterday.map((history, index) => (
                                <motion.span
                                  key={index}
                                  {...framerText(3)}
                                  className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[80%] ml-6  rounded-lg items-center justify-start hover:text-[#EE3364]"
                                  onClick={() =>
                                    handleHistoryClick(history.headingId)
                                  }
                                >
                                  {history.heading}
                                </motion.span>
                              ))}
                            </>
                          )}
                          {histories.last7days.length > 0 && (
                            <>
                              <div className="text-gray-300 text-md ml-2">
                                Last 7 days
                              </div>
                              {histories.last7days.map((history, index) => (
                                <motion.span
                                  key={index}
                                  {...framerText(4)}
                                  className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[80%] ml-6 rounded-lg items-center justify-start hover:text-[#EE3364]"
                                  onClick={() =>
                                    handleHistoryClick(history.headingId)
                                  }
                                >
                                  {history.heading}
                                </motion.span>
                              ))}
                            </>
                          )}

                          {histories.last30days.length > 0 && (
                            <>
                              <div className="text-gray-300 text-md ml-2">
                                Last 30 days
                              </div>
                              {histories.last30days.map((history, index) => (
                                <motion.span
                                  key={index}
                                  {...framerText(5)}
                                  className="overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[80%] ml-6 rounded-lg items-center justify-start hover:text-[#EE3364]"
                                  onClick={() =>
                                    handleHistoryClick(history.headingId)
                                  }
                                >
                                  {history.heading}
                                </motion.span>
                              ))}
                            </>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </li>
                </ul>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;

const framerText = (delay) => {
  return {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: {
      delay: 0.5 + delay / 10,
    },
  };
};

const framerIcon = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: "spring",
    stiffness: 260,
    damping: 20,
    delay: 1.5,
  },
};
