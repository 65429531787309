import React from "react";

const Hero = () => {
  const handleButtonClick = () => {
    window.location.href = "https://hr3sixty.africa/contact/";
  };

  return (
    <div className="bg-gray-900 inline-block w-full py-32 sm:py-40">
      <div className="text-white text-center rounded-xl px-4">
        {/* Responsive H2 */}
        <h2 className="text-base sm:text-lg md:text-xl font-semibold mb-2 mt-12">
          WELCOME TO HR3SIXTY
        </h2>

        {/* Responsive H1 */}
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-medium pb-6 sm:pb-12">
          Prepare for the future of work.
          <br />
        </h1>

        {/* Responsive Paragraph */}
        <p className="text-sm sm:text-base md:text-lg mb-6">
          We recognize the pivotal role of preparedness in driving business
          success.
        </p>

        {/* Call to Action Button */}
        <button
          className="bg-blue-500 text-white px-8 sm:px-12 py-3 rounded-xl text-sm sm:text-lg font-bold"
          onClick={handleButtonClick}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Hero;
