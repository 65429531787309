import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth/AuthConfig";

const Menu = () => {
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const { instance } = useMsal();
  const [authToken, setAuthToken] = useState(null);
  const navigate = useNavigate(); // React Router's navigation hook

  const menuContent = [{ text: "Home", url: "/" }];

  const mobileToggleClick = () => {
    setMobileMenuToggle(!mobileMenuToggle);
  };

  const handleButtonClick = () => {
    setMobileMenuToggle(false);
    setShowPopup(true);
  };

  const handleLoginClicked = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        const { accessToken } = response;
        setAuthToken(accessToken);
        sessionStorage.setItem("authToken", accessToken);
        window.location.pathname = "/chat";
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const handleHomeClick = () => {
    setMobileMenuToggle(false);
    navigate("/");
  };

  return (
    <div>
      {/* Mobile Menu */}
      <div
        className={`fixed top-0 ${
          mobileMenuToggle ? "left-0" : "-left-[75%]"
        } bg-[#000000ce] text-white h-full w-[75%] z-50 md:hidden duration-700`}
      >
        <div className="flex flex-col justify-start items-center pt-20 gap-8">
          <ul className="w-full text-center">
            {menuContent.map((item, index) => (
              <li
                key={index}
                className="block text-lg font-medium text-white py-4"
              >
                <NavLink to={item.url} onClick={handleHomeClick}>
                  {item.text}
                </NavLink>
              </li>
            ))}
            {/* Login Link in Mobile Menu */}
            <li
              onClick={handleButtonClick}
              className="block text-lg font-medium py-4 cursor-pointer hover:underline hover:text-blue-500 transition-all duration-300"
            >
              Login
            </li>
          </ul>
        </div>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex">
        <ul className="flex items-center gap-12 text-[#333]">
          <NavLink
            to="/"
            className="relative text-black hover:text-blue-500 hover:underline hover:decoration-blue-500 transition-all duration-300"
          >
            <li>Home</li>
          </NavLink>
          <button
            onClick={handleButtonClick}
            className="relative text-black hover:text-blue-500 hover:underline hover:decoration-blue-500 transition-all duration-300"
          >
            Login
          </button>
        </ul>
      </nav>

      {/* Hamburger Menu Icon */}
      <div className="md:hidden fixed top-6 right-6 cursor-pointer z-50 mt-6">
        <span onClick={mobileToggleClick}>
          {mobileMenuToggle ? (
            <IoClose size={"26px"} />
          ) : (
            <RxHamburgerMenu size={"26px"} />
          )}
        </span>
      </div>

      {/* Popup Modal */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={popupRef}
            className="relative bg-white p-8 rounded-xl shadow-lg w-full max-w-lg m-4"
          >
            {/* Close Icon */}
            <button
              onClick={handleClosePopup}
              className="absolute top-4 right-4"
            >
              <IoClose
                size={"24px"}
                className="text-gray-700 hover:text-gray-900"
              />
            </button>

            <h2 className="text-2xl font-bold mb-4 text-center">
              We recognize the pivotal role of preparedness in driving business
              success.
            </h2>

            <div className="flex flex-col items-center justify-center">
              <button
                onClick={handleLoginClicked}
                className="flex items-center justify-center text-black px-6 py-3 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                  alt="Microsoft"
                  className="w-6 h-6 mr-2"
                  onClick={handleLoginClicked}
                />
                Login with Microsoft
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Menu;
