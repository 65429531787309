import React from "react";

const About = () => {
  return (
    <div className="bg-gray-50 py-12 md:py-36 p-2">
      <div className="flex flex-col md:flex-row items-center gap-12 container mx-auto">
        <div>
          <h2 className="text-[#444444] text-[54px] font-bold">
            Our approach on helping our clients:
          </h2>
          <ul className="text-[#777777] text-lg font-[400] diffFont py-8 list-disc">
            <li>Selecting the best talent for your business.</li>
            <li>
              Leveraging data and conducting insightful analytics to enhance
              business outcomes.
            </li>
            <li>
              Implementing cutting-edge technology to streamline and expedite
              business operations.
            </li>
          </ul>
        </div>
        <img
          src="/images/hr.png"
          className="w-[550px] h-[550px] object-cover"
          alt=""
        />
      </div>
    </div>
  );
};
export default About;
