import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { getUsernameInitials } from "../utils/utils";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Divider from "@mui/material/Divider";

const MySwal = withReactContent(Swal);

function Popupmodal() {
  const { instance, accounts } = useMsal();
  const [initials, setInitials] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleLogoutMicrosoft = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  const showModal = () => {
    MySwal.fire({
      title: `<p style="font-size: 21px;">${name}</p>`,
      html: (
        <div>
          <p>{email}</p>
          <Divider className="bg-gray-700 my-2" />
          <p
            onClick={handleLogoutMicrosoft}
            className="leading-relaxed text-black cursor-pointer hover:text-red-600 "
          >
            Logout
          </p>
        </div>
      ),
      showCloseButton: true,
      showCancelButton: false,
      position: "top-end",
      focusConfirm: false,
      showConfirmButton: false,
      customClass: {
        popup: "top-0 right-0 mt-0 mr-0 w-96",
        htmlContainer: "text-left",
      },
    });
  };

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      setName(account.name);
      setEmail(account.username);
      setInitials(getUsernameInitials(account.name));
    }
  }, [accounts]);

  return (
    <div className="absolute top-0 right-0 m-4">
      <button
        onClick={showModal}
        className="text-white rounded-full w-12 h-12 bg-[#EE3364] hover:cursor-pointer flex items-center justify-center"
      >
        {initials}
      </button>
    </div>
  );
}
export default Popupmodal;
