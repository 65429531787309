import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import About from "../components/About/About";
import WhatWeDo from "../components/WhatWeDo/WhatWeDo";
import Footer from "../components/Footer/Footer";

function Home() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <WhatWeDo />
      <Footer />
    </>
  );
}

export default Home;
