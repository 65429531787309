import React from "react";
import MessageInput from "../chat/MessageInput";
import InsideBar from "../components/sidebar/InsideBar";

function Getstarted() {
  return (
    <div className="flex h-full bg-gray-50">
      <InsideBar />
      <div className="fixed bottom-0 left-0 right-0 p-4">
        <MessageInput />
      </div>
    </div>
  );
}

export default Getstarted;
