import React from "react";

const Footer = () => {
  return (
    <div className="py-4 px-4 text-center bg-black">
      <div className="flex flex-col justify-center items-center text-white">
        <p className="font-bold pt-12 text-white diffFont">
          © All Rights Reserved -{" "}
          <span className="text-[#E6196E]">HR 3Sixty </span> 2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
