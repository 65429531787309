import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/AuthConfig";

// Create an MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Create a root element to render the React app
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);
